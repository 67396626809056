import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['serviceAgreementId', 'serviceEquipmentRateId']

  connect () {
    return this.initialize_selects
  }

  // Update equipment rates
  //
  // #service_equipment_rate_equipment_rate_id
  updateEquipmentRates (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `equipment_rate_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  applyEquipmentRate (event) {
    var table, table_rate
    table = document.getElementById('equipment-rate-table')
    table_rate = table.rows[9].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    if (!table_rate) {
      table_rate = '0.00'
    }
    document.getElementById('service_equipment_rate_rate').value = table_rate
    table_rate = table.rows[10].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    if (!table_rate) {
      table_rate = '0.00'
    }
    return (document.getElementById(
      'service_equipment_rate_rate_pay'
    ).value = table_rate)
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/service_agreements/${this.serviceAgreementIdTarget.value}/service_equipment_rates/${this.serviceEquipmentRateIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#service_equipment_rate_equipment_rate_id').selectpicker()
  }
}
