import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "isFlowComputer" ]

  connect() {
    return this.deviceTypeHideShow;
  }

  // Update the form when the isFlowComputer checkbox has changed.
  updateFlowComputer() {
    return this.deviceTypeHideShow;
  }

  // Hide the flow run attributes based on the isFlowComputer attribute.
  get deviceTypeHideShow() {
    if (this.isFlowComputerTarget.checked == true) {
      document.getElementById('automation_module_gas_flow_runs').closest('div.row').style.display = '';
      document.getElementById('automation_module_liquid_flow_runs').closest('div.row').style.display = '';
    } else {
      document.getElementById('automation_module_gas_flow_runs').closest('div.row').style.display = 'None';
      document.getElementById('automation_module_liquid_flow_runs').closest('div.row').style.display = 'None';
    }
  };
}
