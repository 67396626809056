import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pay-schedules"
export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#pay_schedule_branch_id').selectpicker()
    window.$('#pay_schedule_frequency').selectpicker()
    window.$('#pay_schedule_time_zone').selectpicker()
  }
}
