import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['workTicketId', 'requestId']

  // When a new set of nested fields is produced initialize
  // the selectpicker for live search enabled select boxes
  connect () {
    this.initialize_selects
    this.requestCompleteHideShow
    // Setup Trix Editor
    var editor
    editor = document.querySelector('trix-editor#work_ticket_work_description')
    editor.addEventListener('trix-file-accept', function (e) {
      return e.preventDefault()
    })
    document.querySelector('.trix-button-group--file-tools').style.display =
      'None'

    const equipmentFields = document.getElementById(
      'work-ticket-nested-field-equipment'
    )
    equipmentFields.addEventListener('cocoon:after-insert', added_item => {
      return window
        .$('#work-ticket-nested-field-equipment')
        .find('.selectpicker')
        .selectpicker()
    })

    const labourFields = document.getElementById(
      'work-ticket-nested-field-labour'
    )
    labourFields.addEventListener('cocoon:after-insert', added_item => {
      return window
        .$('#work-ticket-nested-field-labour')
        .find('.selectpicker')
        .selectpicker()
    })

    const itemFields = document.getElementById(
      'work-ticket-nested-field-material'
    )
    itemFields.addEventListener('cocoon:after-insert', added_item => {
      return window
        .$('#work-ticket-nested-field-material')
        .find('.with-ajax')
        .selectpicker()
        .ajaxSelectPicker({
          ajax: {
            url: this.ajaxURL,
            type: 'GET',
            dataType: 'json',
            data: {
              q: '{{{q}}}'
            }
          },
          locale: {
            emptyTitle: 'Select and Begin Typing'
          },
          requestDelay: 500,
          minLength: 2,
          log: 0,
          preprocessData: function (data) {
            var array, i, l
            i = 0
            l = data.length
            array = []
            if (l) {
              i = 0
              while (i < l) {
                array.push(
                  $.extend(true, data[i], {
                    text: data[i].name,
                    value: data[i].id,
                    data: {
                      subtext: data[i].part_number
                    }
                  })
                )
                i++
              }
            }
            return array
          }
        })
    })
  }

  // Update the availble Areas, Locations and Clients (select boxes) when the
  // Customer changes via AJAX.
  //
  // #work_ticket_customer_id
  updateCustomer (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=customer;customer_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations and Clients (select boxes) when the
  // Area changes via AJAX.
  //
  // #work_ticket_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Clients, work_tickets (select boxes) when the Location changes via AJAX.
  //
  // #work_ticket_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Employees (select boxes) when the Branch changes via AJAX.
  //
  // #work_ticket_branch_id
  updateBranch (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=branch;branch_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the visibility of the work request complete checkbox on change.
  //
  // #work_ticket_work_request_id
  updateWorkRequest () {
    this.requestCompleteHideShow
  }

  // Hide work request field when complete
  get requestCompleteHideShow () {
    if (this.requestIdTarget.value === '') {
      document
        .getElementById('work_ticket_work_request_complete')
        .closest('div.row').style.display = 'None'
    } else {
      document
        .getElementById('work_ticket_work_request_complete')
        .closest('div.row').style.display = ''
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/work_tickets/${this.workTicketIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#work_ticket_branch_id').selectpicker()
    window.$('#work_ticket_employee_id').selectpicker()
    window.$('#work_ticket_customer_id').selectpicker()
    window.$('#work_ticket_area_id').selectpicker()
    window.$('#work_ticket_location_id').selectpicker()
    window.$('#work_ticket_client_id').selectpicker()
    window.$('#work_ticket_quote_id').selectpicker()
    window.$('#work_ticket_work_request_id').selectpicker()
    if (this.element.id == 'work-ticket-edit') {
      window.$('#work-ticket-nested-field-labour .selectpicker').selectpicker()
      window
        .$('#work-ticket-nested-field-equipment .selectpicker')
        .selectpicker()
      window
        .$('#work-ticket-nested-field-material .selectpicker')
        .selectpicker()
    }
  }
}
