import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['causableType', 'parentTag']

  connect () {
    this.mainTagHideShow
    this.delayHideShow
    this.initialize_selects
    const effectFields = document.querySelector('#cause-edit-effects-table')
    if (effectFields != null) {
      effectFields.addEventListener('cocoon:after-insert', added_item => {
        return window.$('.selectpicker').selectpicker()
      })
    }
  }

  // Update the form when the cause tag has changed.
  updateCauseTag () {
    this.mainTagHideShow
    return this.delayHideShow
  }

  // Update the form when the bypass class has changed.
  updateBypassClass () {
    return this.delayHideShow
  }

  // Hide the appropriate attributes based on the device type.
  get mainTagHideShow () {
    if (
      this.parentTagTarget.value === document.getElementById('cause_tag').value
    ) {
      document
        .getElementById('cause_description')
        .closest('div.row').style.display = 'None'
      if (this.causableTypeTarget.value === 'DigitalDevice') {
        return (document.getElementById('cause_setpoint').readOnly = true)
      } else {
        document
          .getElementById('cause_setpoint')
          .closest('div.row').style.display = 'None'
        document
          .getElementById('cause_bypass_class')
          .closest('div.row').style.display = 'None'
        document
          .getElementById('cause_latched')
          .closest('div.row').style.display = 'None'
        document
          .getElementById('cause_local_annun')
          .closest('div.row').style.display = 'None'
        document
          .getElementById('cause_remote_annun')
          .closest('div.row').style.display = 'None'
      }
    } else {
      document
        .getElementById('cause_description')
        .closest('div.row').style.display = ''
      if (this.causableTypeTarget.value === 'DigitalDevice') {
        return (document.getElementById('cause_setpoint').readOnly = true)
      } else {
        document
          .getElementById('cause_setpoint')
          .closest('div.row').style.display = ''
        document
          .getElementById('cause_bypass_class')
          .closest('div.row').style.display = ''
        document
          .getElementById('cause_latched')
          .closest('div.row').style.display = ''
        document
          .getElementById('cause_local_annun')
          .closest('div.row').style.display = ''
        document
          .getElementById('cause_remote_annun')
          .closest('div.row').style.display = ''
      }
    }
  }

  // Hide the delay attribute based on bypass class type.
  get delayHideShow () {
    if (
      document.getElementById('cause_bypass_class').value ==
      "Class 'B' - Time Delayed Shutdown"
    ) {
      return (document
        .getElementById('cause_delay')
        .closest('div.row').style.display = '')
    } else {
      return (document
        .getElementById('cause_delay')
        .closest('div.row').style.display = 'None')
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#cause_bypass_class').selectpicker()
  }
}
