import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['deviceType', 'ownableType', 'analogDeviceId']

  connect () {
    if (this.element.id != 'analog-device-show') {
      this.initialize_selects
      return this.deviceTypeHideShow
    }
  }

  // Update the availble Area, Locations, Multivariable devices (select box) when the
  // owner changes via AJAX.
  //
  // #analog_device_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations, Multivariable devices (select box) when the area
  // changes via AJAX.
  //
  // #analog_device_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Multivariable devices (select box) when the location
  // changes via AJAX.
  //
  // #analog_device_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // When associated with a multivariable device set the Manufacturer, Model, and
  // Serial from the MultivariableDevice via AJAX.
  //
  // #analog_device_multivariable_device_id
  updateMultivariableDevice (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=multivariable_device;multivariable_device_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the form when the device type has changed.
  updateInputType () {
    return this.deviceTypeHideShow
  }

  // Hide the appropriate attributes based on the device type.
  get deviceTypeHideShow () {
    if (this.deviceTypeTarget.value === 'true') {
      document
        .getElementById('analog_device_create_default_causes')
        .closest('div.row').style.display = ''
    } else {
      document
        .getElementById('analog_device_create_default_causes')
        .closest('div.row').style.display = 'None'
    }
  }

  // Request the show tab data submiting the acive tab with AJAX
  updateShowTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/analog_devices/${this.analogDeviceIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#analog_device_ownable_id').selectpicker()
    window.$('#analog_device_area_id').selectpicker()
    window.$('#analog_device_location_id').selectpicker()
    window.$('#analog_device_multivariable_device_id').selectpicker()
    window.$('#analog_device_input').selectpicker()
  }
}
