import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // Request the form data submiting the acive tab with AJAX
  updateAdminTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    if (event.target.id.split('-')[4] === 'tab') {
      tab = event.target.id.split('-')[3]
    } else {
      tab = event.target.id.split('-')[3] + '-' + event.target.id.split('-')[4]
    }
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#admin_access_code').selectpicker()
  }
}
