import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['documentFormat', 'documentFormatSample', 'companyId']

  connect () {
    this.initialize_selects
    return this.sampleDocumentFormat
  }

  // When company_country_code select changes reload provinces/states with AJAX
  //
  // #company_country_code
  updateCompanyProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Clear the document format text field
  clearDocumentFormat (event) {
    this.documentFormatTarget.value = ''
    event.preventDefault()
    return this.sampleDocumentFormat
  }

  // Add a element to the document format text box.
  addDocumentFormat (event) {
    var added_string, new_string
    new_string = window.$('#company_document_format_attribute').val()
    if (new_string !== '') {
      if (this.documentFormatTarget.value === '') {
        added_string = '{' + new_string + '}'
      } else {
        added_string = this.documentFormatTarget.value + '{' + new_string + '}'
      }
      event.preventDefault()
      this.documentFormatTarget.value = added_string
      return this.sampleDocumentFormat
    }
  }

  // Create an example string from the document format
  get sampleDocumentFormat () {
    var cleanElement, date, documentString, element, sourceArray, _i, _len
    sourceArray = this.documentFormatTarget.value.split('}{')
    documentString = 'Ex. '
    date = new Date()
    for (_i = 0, _len = sourceArray.length; _i < _len; _i++) {
      element = sourceArray[_i]
      cleanElement = element.replace('{', '').replace('}', '')
      switch (cleanElement) {
        case 'Calendar Year':
          documentString += date.getFullYear() % 100
          break
        case 'Calendar Month':
          documentString += date.getMonth()
          break
        case 'Calendar Day':
          documentString += date.getDay()
          break
        case 'Fiscal Year':
          documentString += date.getFullYear() % 100
          break
        case 'Fiscal Month':
          documentString += date.getMonth()
          break
        case 'Document Number':
          documentString += '0043'
          break
        case '#':
          documentString += '#'
          break
        case '-':
          documentString += '-'
          break
        case '.':
          documentString += '.'
          break
        case 'Space':
          documentString += ' '
      }
    }
    return (this.documentFormatSampleTarget.innerText = documentString)
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/companies/${this.companyIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#company_country_code').selectpicker()
    window.$('#company_province_code').selectpicker()
    window.$('#company_currency').selectpicker()
    window.$('#company_business_type').selectpicker()
    window
      .$('#company_company_company_document_format_attribute')
      .selectpicker()
    window.$('#company_calendar_rollover').selectpicker()
  }
}
