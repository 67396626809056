import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.initialize_selects
    this.companyOwnedHideShow
    this.equipmentTypeHideShow
  }

  // Update the form when the owned by attribute has changed.
  updateEmployee () {
    this.companyOwnedHideShow
  }

  // Update the form when the equipment type by attribute has changed.
  updateEquipment () {
    this.equipmentTypeHideShow
  }

  // Hide the appropriate attributes based on equipment being company owned or not.
  // DO NOT display the payroll rate if the equipment is company owned
  get companyOwnedHideShow () {
    if (document.getElementById('equipment_rate_employee_id').value === '') {
      return (document
        .getElementById('equipment_rate_rate_pay')
        .closest('div.row').style.display = 'None')
    } else {
      return (document
        .getElementById('equipment_rate_rate_pay')
        .closest('div.row').style.display = '')
    }
  }

  // Hide the appropriate attributes based on equipment being company owned or not.
  // DO NOT display the has reports attrbite unless the equipment type is 'Test Equipment'
  get equipmentTypeHideShow () {
    if (
      document.getElementById('equipment_rate_equipment_type').value ===
      'test_equipment'
    ) {
      return (document
        .getElementById('equipment_rate_has_reports')
        .closest('div.row').style.display = '')
    } else {
      return (document
        .getElementById('equipment_rate_has_reports')
        .closest('div.row').style.display = 'None')
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#equipment_rate_branch_id').selectpicker()
    window.$('#equipment_rate_employee_id').selectpicker()
    window.$('#equipment_rate_equipment_type').selectpicker()
    window.$('#equipment_rate_kit_id').selectpicker()
  }
}
