import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    if (this.element.id == 'employee-sign') {
      if (document.querySelector('.sigPad.unsigned') !== null) {
        return window.$('.sigPad').signaturePad({
          drawOnly: true,
          lineTop: 60,
          output: '#employee_signature'
        })
      }
    } else {
      this.initialize_selects
      this.employeetypeHideShow
    }
  }

  // Request the tab data submiting the acive tab with AJAX
  loadEmployeeDashboardPanel (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    if (event.target.id.split('-')[4] === 'tab') {
      tab = event.target.id.split('-')[3]
    } else {
      tab = event.target.id.split('-')[3] + '-' + event.target.id.split('-')[4]
    }
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  updateEmployeeType () {
    this.employeetypeHideShow
  }

  // On click show the reset password div, showing the input fields.
  enableEmployeePasswordChange () {
    document.getElementById('employee_password').disabled = false
    document.getElementById('employee_password_confirmation').disabled = false
    return (document.getElementById('employeePasswordChange').style.display =
      '')
  }

  // Hide the appropriate attributes based on the device type.
  get employeetypeHideShow () {
    if (document.getElementById('employee_is_employee') != null) {
      if (document.getElementById('employee_is_employee').value === 'false') {
        document
          .getElementById('employee_contractor_company_name')
          .closest('div.form-group').style.display = ''
        document
          .getElementById('employee_ticket_percentage')
          .closest('div.form-group').style.display = ''
        return (document
          .getElementById('employee_pay_scheme')
          .closest('div.form-group').style.display = '')
      } else {
        document
          .getElementById('employee_contractor_company_name')
          .closest('div.form-group').style.display = 'None'
        document
          .getElementById('employee_ticket_percentage')
          .closest('div.form-group').style.display = 'None'
        return (document
          .getElementById('employee_pay_scheme')
          .closest('div.form-group').style.display = 'None')
      }
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#employee_branch_id').selectpicker()
    window.$('#employee_pay_schedule_id').selectpicker()
    window.$('#employee_labour_rate_id').selectpicker()
    window.$('#employee_equipment_rate_id').selectpicker()
    window.$('#employee_trade_id').selectpicker()
    window.$('#employee_is_employee').selectpicker()
    window.$('#employee_pay_scheme').selectpicker()
    window.$('#employee_access_code').selectpicker()
    window.$('#employee_time_zone').selectpicker()
  }
}
