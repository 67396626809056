import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['meterType', 'ownableType', 'flowMeterId']

  connect () {
    if (this.element.id != 'flow-meter-show') {
      this.initialize_selects
      return this.meterTypeHideShow
    }
  }

  // Update the availble Area, Locations, Multivariable devices (select box) when the
  // owner changes via AJAX.
  //
  // #flow_meter_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations, Multivariable devices (select box) when the area
  // changes via AJAX.
  //
  // #flow_meter_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Multivariable devices (select box) when the location
  // changes via AJAX.
  //
  // #flow_meter_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the flow meter attributes when the meter type chanes
  updateMeterType () {
    return this.meterTypeHideShow
  }

  // Show/Hide attributes based on the meter type
  get meterTypeHideShow () {
    if (this.meterTypeTarget.value === 'Gas - Orifice') {
      document.getElementById('liquids').style.display = 'None'
      document
        .getElementById('flow_meter_dp_tx_id')
        .closest('div.row').style.display = ''
      document
        .getElementById('flow_meter_fq_tx_id')
        .closest('div.row').style.display = 'None'
    }
    if (this.meterTypeTarget.value === 'Gas - V-Cone') {
      document
        .getElementById('flow_meter_fq_tx_id')
        .closest('div.row').style.display = 'None'
      document
        .getElementById('flow_meter_dp_tx_id')
        .closest('div.row').style.display = ''
      document.getElementById('liquids').style.display = 'None'
    }
    if (this.meterTypeTarget.value === 'Gas - Turbine') {
      document
        .getElementById('flow_meter_fq_tx_id')
        .closest('div.row').style.display = ''
      document
        .getElementById('flow_meter_dp_tx_id')
        .closest('div.row').style.display = 'None'
      document.getElementById('liquids').style.display = 'None'
    }
    if (this.meterTypeTarget.value === 'Liquid - Orifice') {
      document
        .getElementById('flow_meter_fq_tx_id')
        .closest('div.row').style.display = 'None'
      document
        .getElementById('flow_meter_dp_tx_id')
        .closest('div.row').style.display = ''
      document.getElementById('liquids').style.display = ''
    }
    if (this.meterTypeTarget.value === 'Liquid - Turbine') {
      document
        .getElementById('flow_meter_fq_tx_id')
        .closest('div.row').style.display = ''
      document
        .getElementById('flow_meter_dp_tx_id')
        .closest('div.row').style.display = 'None'
      return (document.getElementById('liquids').style.display = '')
    }
  }

  // Request the show tab data submiting the acive tab with AJAX
  updateShowTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/flow_meters/${this.flowMeterIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#flow_meter_ownable_id').selectpicker()
    window.$('#flow_meter_area_id').selectpicker()
    window.$('#flow_meter_location_id').selectpicker()
    window.$('#flow_meter_automation_device_id').selectpicker()
    window.$('#flow_meter_meter_type').selectpicker()
    window.$('#flow_meter_fq_tx_id').selectpicker()
    window.$('#flow_meter_dp_tx_id').selectpicker()
    window.$('#flow_meter_pt_tx_id').selectpicker()
    window.$('#flow_meter_tt_tx_id').selectpicker()
    window.$('#flow_meter_wc_tx_id').selectpicker()
    window.$('#flow_meter_density_tx_id').selectpicker()
  }
}
