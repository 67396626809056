import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "source" ]
  static values = {
    mask: String
  }

  // When controller connects configure the InputMask
  connect() {
    if (this.hasSourceTarget) {
      Inputmask({
        'mask': this.maskValue,
        'removeMaskOnSubmit': true
      }).mask(this.sourceTarget);
    }
  }
}
