// Place all the behaviors and hooks related to the matching controller here.

// Image model display
window.showImageModal = function(element) {
  $('.imagepreview').attr('src', $(element).attr('src'));
  return window.$('#imagemodal').modal('show');
};

// Showcase filter
window.$(function() {
  return window.$('#showcase-flters li').click(function() {
    var selectedFilter;
    window.$('#showcase-flters li').removeClass('filter-active');
    window.$(this).addClass('filter-active');
    selectedFilter = $(this).data('filter');
    window.$('#showcase-wrapper').fadeTo(100, 0);
    window.$('.showcase-item').fadeOut().css('transform', 'scale(0)');
    setTimeout((function() {
      window.$(selectedFilter).fadeIn(100).css('transform', 'scale(1)');
      window.$('#showcase-wrapper').fadeTo(300, 1);
    }), 300);
  });
});

$(document).on('turbo:load', function() {
  // Header fixed and Back to top button
  window.$('.back-to-top').fadeOut('slow');
  window.$(window).scroll(function() {
    if ($(window).height() + window.$(this).scrollTop() === $(document).height()) {
      window.$('.back-to-top').fadeOut('slow');
    } else if (window.$(this).scrollTop() > 100) {
      window.$('.back-to-top').fadeIn('slow');
      window.$('#header').addClass('header-fixed');
    } else {
      window.$('.back-to-top').fadeOut('slow');
      window.$('#header').removeClass('header-fixed');
    }
  });
  window.$('.back-to-top').click(function() {
    window.$('html, body').animate({
      scrollTop: 0
    }, 1500, 'easeInOutExpo');
    return false;
  });
  // Initiate the wowjs
  (new WOW).init();
  // Mobile Navigation
  if (window.$('#nav-menu-container').length) {
    window.$(document).on('click', '.menu-has-children i', function(e) {
      window.$(this).next().toggleClass('menu-item-active');
      window.$(this).nextAll('ul').eq(0).slideToggle();
      window.$(this).toggleClass('fa-chevron-up fa-chevron-down');
    });
  }

  // Smoth scroll on page hash links
  window.$('a[href*="#"]:not([href="#"])').on('click', function() {
    var target, top_space;
    if (document.querySelector('.public-index') !== null) {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        target = window.$(this.hash);
        if (target.length) {
          top_space = 0;
          if (window.$('#header').length) {
            top_space = window.$('#header').outerHeight();
            if (!window.$('#header').hasClass('header-fixed')) {
              top_space = top_space - 20;
            }
          }
          window.$('html, body').animate({
            scrollTop: target.offset().top - top_space
          }, 1500, 'easeInOutExpo');
          if (window.$(this).parents('.nav-menu').length) {
            window.$('.nav-menu .menu-active').removeClass('menu-active');
            window.$(this).closest('li').addClass('menu-active');
          }
          return false;
        }
      }
    }
  });

  // Smooth scroll on page hash links
  window.$('#showcase-flters li').click(function() {
    var selectedFilter;
    window.$('#showcase-flters li').removeClass('filter-active');
    window.$(this).addClass('filter-active');
    selectedFilter = window.$(this).data('filter');
    window.$('#showcase-wrapper').fadeTo(100, 0);
    window.$('.showcase-item').fadeOut().css('transform', 'scale(0)');
    setTimeout((function() {
      window.$(selectedFilter).fadeIn(100).css('transform', 'scale(1)');
      window.$('#showcase-wrapper').fadeTo(300, 1);
    }), 300);
  });
});
