import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['serviceAgreementId', 'serviceRateId']

  connect () {
    return this.initialize_selects
  }

  // Update labour rates
  //
  // #service_rate_trade_id
  updateLabourRates (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `trade_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  applyRate (event) {
    var row
    row = event.srcElement.parentElement
    if (row.nodeName !== 'THEAD') {
      document.getElementById(
        'service_rate_regular_rate'
      ).value = row.cells[3].innerHTML.replace(/[^\d\.]/g, '')
      document.getElementById(
        'service_rate_overtime_rate'
      ).value = row.cells[4].innerHTML.replace(/[^\d\.]/g, '')
      document.getElementById(
        'service_rate_holiday_rate'
      ).value = row.cells[5].innerHTML.replace(/[^\d\.]/g, '')
      if (row.cells.length >= 6) {
        document.getElementById(
          'service_rate_regular_pay'
        ).value = row.cells[6].innerHTML.replace(/[^\d\.]/g, '')
        document.getElementById(
          'service_rate_overtime_pay'
        ).value = row.cells[7].innerHTML.replace(/[^\d\.]/g, '')
        return (document.getElementById(
          'service_rate_holiday_pay'
        ).value = row.cells[8].innerHTML.replace(/[^\d\.]/g, ''))
      }
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/service_agreements/${this.serviceAgreementIdTarget.value}/service_rates/${this.serviceRateIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#service_rate_trade_id').selectpicker()
  }
}
