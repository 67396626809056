import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    if (this.element.id != 'customer-show') {
      return this.initialize_selects
    }
  }

  // Request the form data submiting the acive tab with AJAX (assets only)
  updateCustomerTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#customer_industry').selectpicker()
  }
}
