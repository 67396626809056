import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "source" ]

  // When controller connects configure the InputMask
  connect() {
    var tempus_node

    if (this.hasSourceTarget) {
      tempus_node = document.getElementById(this.sourceTarget.id)
      if (this.sourceTarget.id != tempus_node.parentNode.id) {
        tempus_node.parentNode.id = this.sourceTarget.id
      }
      if (this.sourceTarget.id != tempus_node.parentNode.getElementsByTagName('span')[0].getAttribute('data-td-target')) {
        tempus_node.parentNode.getElementsByTagName('span')[0].getAttribute('data-td-target', this.sourceTarget.id)
      }
      return new tempusDominus.TempusDominus(document.getElementById(this.sourceTarget.id), {
        hooks: {
          inputFormat: function(context, date) {
            return date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        restrictions: {
          minDate: new Date(Date.now() - (365 * 24 * 60 * 60 * 1000)),
          maxDate: new Date(Date.now() + (10 * 365 * 24 * 60 * 60 * 1000))
        },
        display: {
          buttons: {
            close: true
          },
          icons: {
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            close: 'fas fa-times',
            clear: 'fas fa-trash'
          },
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });
    }
  }
}
