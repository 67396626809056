import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['ownableType', 'automationDeviceId']

  connect () {
    if (this.element.id != 'automation-device-show') {
      this.initialize_selects
      return this.automationModuleAjaxSelect
    }
  }

  // Update the availble Area, Locations, Multivariable devices (select box) when the
  // owner changes via AJAX.
  //
  // #automation_device_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations, Multivariable devices (select box) when the area
  // changes via AJAX.
  //
  // #automation_device_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Multivariable devices (select box) when the location
  // changes via AJAX.
  //
  // #automation_device_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the show tab data submiting the acive tab with AJAX
  updateShowTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/automation_devices/${this.automationDeviceIdTarget.value}/selects_ajax`
  }

  get automationModuleAjaxSelect () {
    return window
      .$('.selectpicker.with-ajax')
      .selectpicker()
      .ajaxSelectPicker({
        ajax: {
          url: this.ajaxURL,
          type: 'GET',
          dataType: 'json',
          data: function () {
            return {
              option: 'automation_module',
              automatable_type: $(
                '#automation_device_automatable_type option:selected'
              ).val(),
              q: '{{{q}}}'
            }
          }
        },
        locale: {
          emptyTitle: 'Select and Begin Typing'
        },
        requestDelay: 500,
        minLength: 2,
        log: 0,
        preprocessData: function (data) {
          var array, desc, dots, i, l, length
          i = 0
          l = data.length
          array = []
          if (l) {
            i = 0
            while (i < l) {
              length = data[i].name.length
              if (data[i].description.length > 80 - length) {
                dots = '...'
              } else {
                dots = ''
              }
              desc = data[i].description.substring(0, 80 - length) + dots
              array.push(
                $.extend(true, data[i], {
                  text: data[i].name,
                  value: data[i].id,
                  data: {
                    subtext: desc
                  }
                })
              )
              i++
            }
          }
          return array
        }
      })
  }

  get initialize_selects () {
    window.$('#automation_device_ownable_id').selectpicker()
    window.$('#automation_device_area_id').selectpicker()
    window.$('#automation_device_location_id').selectpicker()
    window.$('#automation_device_control_panel_id').selectpicker()
    window.$('#automation_device_automatable_type').selectpicker()
    window.$('#automation_device_automatable_id').selectpicker()
    window.$('#automation_device_primary_id').selectpicker()
    window.$('#automation_device_comms_protocol').selectpicker()
  }
}
