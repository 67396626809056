import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['branchId']

  connect () {
    return this.initialize_selects
  }

  // When branch_country_code select changes reload provinces/states with AJAX
  //
  // #branch_country_code
  updateBranchProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the form data submiting the acive tab with AJAX (assets only)
  updateBranchTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/branches/${this.branchIdTarget.value}/selects_ajax`
  }

  get initialize_selects () {
    window.$('#branch_currency').selectpicker()
    window.$('#branch_country_code').selectpicker()
    window.$('#branch_province_code').selectpicker()
  }
}
