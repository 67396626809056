import { Controller } from '@hotwired/stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = {
    message: { type: String, default: '' },
    icon: { type: String, default: 'success' }
  }

  connect () {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: this.messageValue,
      icon: this.iconValue
    })
  }
}
