import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // When company_country_code select changes reload provinces/states with AJAX
  //
  // #company_country_code
  updateCompanyProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=company;country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // When branch_country_code select changes reload provinces/states with AJAX
  //
  // #branch_country_code
  updateBranchProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=branch;country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return 'selects_ajax'
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    if (document.getElementById('registration-company-new') != null) {
      window.$('#company_country_code').selectpicker()
      window.$('#company_province_code').selectpicker()
      window.$('#company_currency').selectpicker()
      window.$('#company_business_type').selectpicker()
    }
    if (document.getElementById('registration-branch-new') != null) {
      window.$('#branch_country_code').selectpicker()
      window.$('#branch_province_code').selectpicker()
      window.$('#branch_currency').selectpicker()
    }
    if (document.getElementById('registration-employee-new') != null) {
      window.$('#employee_time_zone').selectpicker()
    }
  }
}
