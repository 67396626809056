import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-ticket-show"
export default class extends Controller {
  // Display/hide the invoice number form on user click.
  updateInvoiceNumber () {
    return this.invoiceNumberHideShow
  }
  // Hide the mark as invoiced button.
  markInvoiced () {
    return this.markInvoicedHide
  }

  // Display/hide the invoice number form on user click.
  get invoiceNumberHideShow () {
    if (document.getElementById('work-ticket-invoice-number').classList.contains('d-none')) {
      document.getElementsByName('invoiceNumberAction')[0].innerText = 'Cancel'
      return document.getElementById('work-ticket-invoice-number').classList.remove('d-none')
    } else {
      document.getElementsByName('invoiceNumberAction')[0].innerText = 'Change Invoice Number'
      return document.getElementById('work-ticket-invoice-number').classList.add('d-none')
    }
  }

  // Display/hide the Mark as invoiced/invoice number form on user click.
  get markInvoicedHide () {
    if (document.getElementById('work-ticket-invoice-number').classList.contains('d-none')) {
      document.getElementsByName('invoiceNumberAction')[0].innerText = 'Cancel'
      document.getElementsByName('invoiceNumberAction')[0].classList.replace('btn-success', 'btn-secondary')
      return document.getElementById('work-ticket-invoice-number').classList.remove('d-none')
    } else {
      document.getElementsByName('invoiceNumberAction')[0].innerText = 'Mark Invoiced'
      document.getElementsByName('invoiceNumberAction')[0].classList.replace('btn-secondary', 'btn-success')
      return document.getElementById('work-ticket-invoice-number').classList.add('d-none')
    }
  }

}
