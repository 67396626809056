import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(ChartDataLabels)

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    data: Object,
    options: Object
  }

  chart = undefined

  connect () {
    var element = this.hasCanvasTarget ? this.canvasTarget : this.element
    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions
    })
  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData () {
    if (!this.hasDataValue) {
      console.warn('[chartjs] You need to pass data as JSON to see the chart.')
    }

    return this.dataValue
  }

  get chartOptions () {
    var opts = {
      ...this.defaultOptions,
      ...this.optionsValue
    }
    if (opts.plugins.datalabels.formatter != null) {
      var units = opts.plugins.datalabels.formatter
      opts.plugins.datalabels['formatter'] = function (value, context) {
        return value + ' ' + units
      }
    }
    if (opts.plugins.tooltip.callbacks.title != null) {
      var units = opts.plugins.tooltip.callbacks.title
      opts.plugins.tooltip.callbacks['title'] = function (tooltipItems, data) {
        return 'Applied Value: ' + tooltipItems[0].label + ' ' + units
      }
      opts.plugins.tooltip.callbacks['afterLabel'] = function (_tooltipItem) {
        return ' ' + units
      }
    }
    return opts
  }
}
