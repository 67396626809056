import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "source" ]

  // Update the file name value when file is selected
  updateFileName () {
    var fileName, nextSibling;
      fileName = this.sourceTarget.files[0].name;
      nextSibling = this.sourceTarget.nextElementSibling;
      nextSibling.innerText = fileName;
  }
}
