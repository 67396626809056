import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="feedbacks"
export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#feedback_category').selectpicker()
    window.$('#feedback_state').selectpicker()
  }
}
