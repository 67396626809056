import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['clientId']
  connect () {
    this.initialize_selects
    //initialize selectpicker on new access perrmission nested fields
    const accessPermissions = document.querySelector(
      '#client-access-location-area'
    )
    if (accessPermissions != null) {
      accessPermissions.addEventListener('cocoon:after-insert', added_item => {
        return window.$('.selectpicker').selectpicker()
      })
    }
    // Inititalize signaturepad
    if (this.element.id == 'client-sign') {
      if (document.querySelector('.sigPad.unsigned') !== null) {
        return window.$('.sigPad').signaturePad({
          drawOnly: true,
          lineTop: 60,
          output: '#client_signature'
        })
      }
    } else {
      this.accessType
    }
  }

  // Request the tab data submiting the acive tab with AJAX
  loadClientDashboardPanel (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    if (event.target.id.split('-')[4] === 'tab') {
      tab = event.target.id.split('-')[3]
    } else {
      tab = event.target.id.split('-')[3] + '-' + event.target.id.split('-')[4]
    }
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Update link to add association button on access code change
  updateAccessCode (event) {
    var customer_id
    customer_id = document.getElementById('client_customer_id').value
    this.accessType
    this.deletedFields
    Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${event.srcElement.value};customer_id=${customer_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update link to add association button on customer change
  updateCustomer (event) {
    var option
    option = document.getElementById('client_access_code').value
    this.accessType
    this.deletedFields
    Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${option};customer_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // On click show the reset password div, showing the input fields.
  get enableClientPasswordChange () {
    window.$('#client_password')[0].disabled = false
    window.$('#client_password_confirmation')[0].disabled = false
    return window.$('#clientPasswordChange').show()
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/clients/${this.clientIdTarget.value}/selects_ajax`
  }

  get deletedFields () {
    var nodes = document
      .getElementById('client-client-accesses')
      .querySelectorAll('.remove_fields')

    for (let i = 0; i < nodes.length; i++) {
      nodes[i].click()
    }
  }

  // Determine access type
  get accessType () {
    var selected = document.getElementById('client_access_code').value
    if (selected == 'customer_operator' || selected == 'customer_manager') {
      document.getElementById('client-access-customer').style.display = ''
      return (document.getElementById(
        'client-access-location-area'
      ).style.display = 'None')
    }
    if (selected == 'area_operator' || selected == 'area_manager') {
      document.getElementById('client-access-customer').style.display = 'None'
      return (document.getElementById(
        'client-access-location-area'
      ).style.display = '')
    }
    if (selected == 'location_operator' || selected == 'location_manager') {
      document.getElementById('client-access-customer').style.display = 'None'
      return (document.getElementById(
        'client-access-location-area'
      ).style.display = '')
    }
  }

  // Determine fields to remove
  get removeFields () {
    var selected = document.getElementById('client_access_code').value
    if (selected == 'area_operator' || selected == 'area_manager') {
      return 'location'
    }
    if (selected == 'location_operator' || selected == 'location_manager') {
      return 'area'
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#client_customer_id').selectpicker()
    window.$('#client_time_zone').selectpicker()
    window.$('#client_access_code').selectpicker()
    window.$('#client-access-location-area .selectpicker').selectpicker()
  }
}
