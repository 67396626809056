import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['reportId']

  connect () {
    if (this.element.id == 'calibration-report-flow-meter-edit') {
      this.check_to_hide_or_show_add_fq_report_link
      this.check_to_hide_or_show_add_dp_report_link
      this.check_to_hide_or_show_add_pt_report_link
      this.check_to_hide_or_show_add_tt_report_link
      this.check_to_hide_or_show_add_wc_report_link
      this.check_to_hide_or_show_add_density_report_link
    }

    if (this.element.id == 'calibration-report-flow-meter-new') {
      this.addTxReportsOnNew
    }

    if (this.element.id != 'calibration-report-flow-meters') {
      this.initialize_selects

      // Initialize compositions selectpicker
      const compositionFields = document.querySelector(
        '#calibration-report-flow-meter-composition'
      )
      if (compositionFields != null) {
        compositionFields.addEventListener(
          'cocoon:after-insert',
          added_item => {
            return window.$('.selectpicker').selectpicker()
          }
        )
      }
      // Show or hide the fq test fields and add report button on cocoon events.
      const fqFields = document.querySelector(
        '#calibration-report-flow-meter-fq-report'
      )
      if (fqFields != null) {
        fqFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-fq-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        fqFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-fq-report').show()
          $(
            '#calibration-report-flow-meter-fq-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-fq-test-fields .form-buttons'
          ).hide()
        })
      }
      // Show or hide the dp test fields and add report button on cocoon events.
      const dpFields = document.querySelector(
        '#calibration-report-flow-meter-dp-report'
      )
      if (dpFields != null) {
        dpFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-dp-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        dpFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-dp-report').show()
          $(
            '#calibration-report-flow-meter-dp-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-dp-test-fields .form-buttons'
          ).hide()
        })
      }

      // Show or hide the pt test fields and add report button on cocoon events.
      const ptFields = document.querySelector(
        '#calibration-report-flow-meter-pt-report'
      )
      if (ptFields != null) {
        ptFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-pt-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        ptFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-pt-report').show()
          $(
            '#calibration-report-flow-meter-pt-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-pt-test-fields .form-buttons'
          ).hide()
        })
      }

      // Show or hide the tt test fields and add report button on cocoon events.
      const ttFields = document.querySelector(
        '#calibration-report-flow-meter-tt-report'
      )
      if (ttFields != null) {
        ttFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-tt-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        ttFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-tt-report').show()
          $(
            '#calibration-report-flow-meter-tt-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-tt-test-fields .form-buttons'
          ).hide()
        })
      }

      // Show or hide the tt test fields and add report button on cocoon events.
      const wcFields = document.querySelector(
        '#calibration-report-flow-meter-wc-report'
      )
      if (wcFields != null) {
        wcFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-wc-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        wcFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-wc-report').show()
          $(
            '#calibration-report-flow-meter-wc-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-wc-test-fields .form-buttons'
          ).hide()
        })
      }

      // Show or hide the tt test fields and add report button on cocoon events.
      const densityFields = document.querySelector(
        '#calibration-report-flow-meter-density-report'
      )
      if (densityFields != null) {
        densityFields.addEventListener('cocoon:after-insert', added_item => {
          $('#calibration-report-flow-meter-add-density-report').hide()
          window.$('.selectpicker').selectpicker()
        })
        densityFields.addEventListener('cocoon:after-remove', added_item => {
          $('#calibration-report-flow-meter-add-density-report').show()
          $(
            '#calibration-report-flow-meter-density-test-fields .nested-fields'
          ).hide()
          $(
            '#calibration-report-flow-meter-density-test-fields .form-buttons'
          ).hide()
        })
      }
    }
  }

  // Request the tab data submiting the acive tab with AJAX
  updateDevicesTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/calibration_reports/${this.reportIdTarget.value}/calibration_report_flow_meters`
  }

  // Hide the add fq_report button if a report exists.
  get check_to_hide_or_show_add_fq_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-fq-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-fq-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-fq-report').show()
    }
  }

  // Hide the add dp_report button if a report exists.
  get check_to_hide_or_show_add_dp_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-dp-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-dp-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-dp-report').show()
    }
  }

  // Hide the add pt_report button if a report exists.
  get check_to_hide_or_show_add_pt_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-pt-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-pt-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-pt-report').show()
    }
  }

  // Hide the add tt_report button if a report exists.
  get check_to_hide_or_show_add_tt_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-tt-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-tt-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-tt-report').show()
    }
  }

  // Hide the add wc_report button if a report exists.
  get check_to_hide_or_show_add_wc_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-wc-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-wc-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-wc-report').show()
    }
  }

  // Hide the add density_report button if a report exists.
  get check_to_hide_or_show_add_density_report_link () {
    if (
      $(
        '#calibration-report-flow-meter-density-report-fields .nested-fields:visible'
      ).length >= 1
    ) {
      $('#calibration-report-flow-meter-add-density-report').hide()
    } else {
      $('#calibration-report-flow-meter-add-density-report').show()
    }
  }

  // Add transmitter reports on new form
  get addTxReportsOnNew () {
    if (
      window.$('#calibration-report-flow-meter-fq-report .add_fields').length >
      0
    ) {
      window
        .$('#calibration-report-flow-meter-fq-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-fq-report').hide()
    }
    if (
      window.$('#calibration-report-flow-meter-dp-report .add_fields').length >
      0
    ) {
      window
        .$('#calibration-report-flow-meter-dp-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-dp-report').hide()
    }
    if (
      window.$('#calibration-report-flow-meter-pt-report .add_fields').length >
      0
    ) {
      window
        .$('#calibration-report-flow-meter-pt-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-pt-report').hide()
    }
    if (
      window.$('#calibration-report-flow-meter-tt-report .add_fields').length >
      0
    ) {
      window
        .$('#calibration-report-flow-meter-tt-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-tt-report').hide()
    }
    if (
      window.$('#calibration-report-flow-meter-wc-report .add_fields').length >
      0
    ) {
      window
        .$('#calibration-report-flow-meter-wc-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-wc-report').hide()
    }
    if (
      window.$('#calibration-report-flow-meter-density-report .add_fields')
        .length > 0
    ) {
      window
        .$('#calibration-report-flow-meter-density-report .add_fields')[0]
        .click()
      window.$('#calibration-report-flow-meter-add-density-report').hide()
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#calibration_report_flow_meter_downstream_tap').selectpicker()
    window
      .$('#calibration_report_flow_meter_gauge_static_pressure')
      .selectpicker()
    window
      .$('#calibration_report_flow_meter_calculated_atmospheric')
      .selectpicker()
    window
      .$('#calibration_report_flow_meter_calculated_heating_value')
      .selectpicker()
    window
      .$('#calibration_report_flow_meter_calculated_specific_gravity')
      .selectpicker()
    window
      .$('#calibration_report_flow_meter_compressibility_calculation')
      .selectpicker()
    window
      .$('#calibration_report_flow_meter_constant_gas_quality')
      .selectpicker()
    window.$('#calibration_report_flow_meter_calculation_method').selectpicker()
    window
      .$('#calibration-report-flow-meter-fq-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-dp-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-pt-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-tt-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-wc-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-density-test-fields .selectpicker')
      .selectpicker()
    window
      .$('#calibration-report-flow-meter-composition .selectpicker')
      .selectpicker()
  }
}
