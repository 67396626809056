// Entry point for the build script in your package.json

import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()
import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery
import 'trix'
import '@rails/actiontext'

import * as bootstrap from 'bootstrap'
import '@oddcamp/cocoon-vanilla-js'
import 'JSON2'
import Inputmask from 'inputmask'
window.Inputmask = Inputmask
import WOW from 'wow.js'
window.WOW = WOW
window.tempusDominus = require('@eonasdan/tempus-dominus')

import './controllers'

require('bootstrap-select')
require('ajax-bootstrap-select')

require('./vendor/OverlayScrollbars.min')
require('./vendor/adminlte.min')
require('./vendor/jquery.signaturepad')
require('./vendor/wholly.min')
import './vendor/pagy'
require('./vendor/jquery-easing')

require('./src/global')
require('./src/public')
