import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['quoteId']

  connect () {
    if (this.element.id != 'quote-show') {
      this.initialize_selects
      var editor
      editor = document.querySelector('trix-editor#quote_description')
      editor.addEventListener('trix-file-accept', function (e) {
        return e.preventDefault()
      })
      document.querySelector('.trix-button-group--file-tools').style.display =
        'None'
    }
  }

  // Update the availble Areas, Locations and Clients (select boxes) when the
  // Customer changes via AJAX.
  //
  // #quote_customer_id
  updateCustomer (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=customer;customer_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations and Clients (select boxes) when the
  // Area changes via AJAX.
  //
  // #quote_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Clients (select boxes) when the Location changes via AJAX.
  //
  // #quote_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Employees (select boxes) when the Branch changes via AJAX.
  //
  // #quote_branch_id
  updateBranch (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=branch;branch_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the form data submiting the acive tab with AJAX
  updateQuoteTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/quotes/${this.quoteIdTarget.value}/selects_ajax`
  }

  get initialize_selects () {
    window.$('#quote_branch_id').selectpicker()
    window.$('#quote_employee_id').selectpicker()
    window.$('#quote_customer_id').selectpicker()
    window.$('#quote_area_id').selectpicker()
    window.$('#quote_location_id').selectpicker()
    window.$('#quote_client_id').selectpicker()
  }
}
