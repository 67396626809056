import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // Update the form when approval state has changed.
  updateState () {
    if (document.getElementById('work-request-state-submit') != null) {
      document.getElementById('work-request-state-submit').style.display = ''
    }
    document.getElementById('work-request-approved-by').style.display = ''
    if (document.getElementById('work-request-completed-by') != null) {
      document.getElementById('work-request-completed-by').style.display =
        'None'
    }
    return this.assignedtoHideShow
  }

  // Update the form when complete state has changed.
  updateComplete () {
    if (document.getElementById('work-request-state-submit') != null) {
      document.getElementById('work-request-state-submit').style.display = ''
    }
    this.completedByHideShow
  }

  get assignedtoHideShow () {
    if (document.getElementById('work_request_approved_approve') != null) {
      if (
        document.getElementById('work_request_approved_approve').checked ===
        true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = ''
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = '')
      }
    }
    if (document.getElementById('work_request_approved_deny') != null) {
      if (
        document.getElementById('work_request_approved_deny').checked === true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = 'None'
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = 'None')
      }
    }
    if (document.getElementById('work_request_approved_review') != null) {
      if (
        document.getElementById('work_request_approved_review').checked === true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = 'None'
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = 'None')
      }
    }
  }

  get completedByHideShow () {
    if (document.getElementById('work-request-completed-by') != null) {
      if (document.getElementById('work_request_completed').checked == true) {
        document.getElementById('work_request_assigned_to_id').disabled = true
        document.getElementById('work_request_branch_id').disabled = true
        document.getElementById('work-request-completed-by').style.display = ''
      } else {
        document.getElementById('work_request_assigned_to_id').disabled = false
        document.getElementById('work_request_branch_id').disabled = false
        document.getElementById('work-request-completed-by').style.display =
          'None'
      }
      window.$('#work_request_assigned_to_id').selectpicker('refresh')
      return window.$('#work_request_branch_id').selectpicker('refresh')
    }
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#work_request_completed_by_id').selectpicker()
    window.$('#work_request_assigned_to_id').selectpicker()
    window.$('#work_request_branch_id').selectpicker()
  }
}
