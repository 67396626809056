import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.initialize_selects

    if (this.element.id == 'work-ticket-approval') {
      if (document.querySelector('.sigPad.unsigned') !== null) {
        return window.$('.sigPad').signaturePad({
          drawOnly: true,
          lineTop: 60,
          output: '#work_ticket_signature'
        })
      }
    }
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#work_ticket_client_signed_id').selectpicker()
  }
}
