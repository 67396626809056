import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // When controller connects configure the InputMask
  connect() {
    if (this.element.id == 'shutdown-keys-index') {
      return window.$('#sdk').wholly({
        highlightHorizontal: 'wholly-highlight-horizontal',
        highlightVertical: 'wholly-highlight-vertical',
        holdOnClick: true
      });
    };
  }
}
