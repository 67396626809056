import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    if (this.element.id != 'location-show') {
      return this.initialize_selects
    }
  }

  // Request the form data submiting the acive tab with AJAX
  updateLocationTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#location_branch_id').selectpicker()
    window.$('#location_area_id').selectpicker()
    window.$('#location_location_type').selectpicker()
  }
}
