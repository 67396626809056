import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="areas"
export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  get initialize_selects () {
    window.$('#area_ownable_id').selectpicker()
  }
}
