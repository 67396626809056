import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['workTicketId', 'materialEntryId']

  connect () {
    this.initialize_selects
    return window
      .$('.selectpicker.with-ajax')
      .selectpicker()
      .ajaxSelectPicker({
        ajax: {
          url: this.ajaxURL,
          type: 'GET',
          dataType: 'json',
          data: {
            q: '{{{q}}}'
          }
        },
        locale: {
          emptyTitle: 'Select and Begin Typing'
        },
        requestDelay: 500,
        minLength: 2,
        log: 0,
        preprocessData: function (data) {
          var array, i, l
          i = 0
          l = data.length
          array = []
          if (l) {
            i = 0
            while (i < l) {
              array.push(
                $.extend(true, data[i], {
                  text: data[i].name,
                  value: data[i].id,
                  data: {
                    subtext: data[i].part_number
                  }
                })
              )
              i++
            }
          }
          return array
        }
      })
  }

  // Update rate information when material changes
  //
  // #material_entry_material_id
  updateMaterial (event) {
    if (this.element.id == 'material-entry-edit') {
      return Rails.ajax({
        url: this.ajaxURL,
        type: 'GET',
        dataType: 'script',
        data: `material_id=${event.srcElement.value}`,
        error: function (jqXHR, textStatus, errorThrown) {
          return console.log('AJAX Error: ' + textStatus)
        }
      })
    }
  }

  // Copy the material Rate data from the rate table to the form fields
  copyMaterialRate (event) {
    var value
    value = event.srcElement.innerText
    if (value.includes('$')) {
      value = value.replace(/[^\d\.]/g, '').split('<')[0]
      return (document.getElementById('material_entry_rate').value = value)
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/work_tickets/${this.workTicketIdTarget.value}/material_entries/${this.materialEntryIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#material_entry_material_id').selectpicker()
  }
}
