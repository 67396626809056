import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="email-configuration"
export default class extends Controller {
  connect () {
    console.log('connected')
    return this.initialize_selects
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#email_configuration_to_id').selectpicker()
  }
}
