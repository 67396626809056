import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['quoteId', 'quoteTaskId']

  // When a new set of nested fields is produced initialize
  // the selectpicker for live search enabled select boxes
  connect () {
    this.initialize_selects
    var editor
    editor = document.querySelector('trix-editor#quote_task_description')
    editor.addEventListener('trix-file-accept', function (e) {
      return e.preventDefault()
    })
    document.querySelector('.trix-button-group--file-tools').style.display =
      'None'

    const equipmentFields = document.getElementById(
      'quote-task-nested-field-equipment'
    )
    equipmentFields.addEventListener('cocoon:after-insert', added_item => {
      return window.$('.selectpicker').selectpicker()
    })

    const labourFields = document.getElementById(
      'quote-task-nested-field-labour'
    )
    labourFields.addEventListener('cocoon:after-insert', added_item => {
      return window.$('.selectpicker').selectpicker()
    })

    const itemFields = document.getElementById(
      'quote-task-nested-field-material'
    )
    itemFields.addEventListener('cocoon:after-insert', added_item => {
      return window
        .$('#quote-task-nested-field-material')
        .find('.with-ajax')
        .selectpicker()
        .ajaxSelectPicker({
          ajax: {
            url: this.ajaxURL,
            type: 'GET',
            dataType: 'json',
            data: {
              option: 'material',
              q: '{{{q}}}'
            }
          },
          locale: {
            emptyTitle: 'Select and Begin Typing'
          },
          requestDelay: 500,
          minLength: 2,
          log: 0,
          preprocessData: function (data) {
            var array, i, l
            i = 0
            l = data.length
            array = []
            if (l) {
              i = 0
              while (i < l) {
                array.push(
                  $.extend(true, data[i], {
                    text: data[i].name,
                    value: data[i].id,
                    data: {
                      subtext: data[i].part_number
                    }
                  })
                )
                i++
              }
            }
            return array
          }
        })
    })
  }

  // Populate labour rate fields when a trade is selected.
  updateQuoteTaskLabour (event) {
    var index, trade_id
    index = index = parseInt(element.srcElement.id.split('_')[6])
    trade_id = document.getElementById(event.srcElement.id).value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `index=${index};trade_id=${trade_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Populate equipment rate fields when a equipment is selected.
  updateQuoteTaskEquipment (event) {
    var index, equipment_id
    index = parseInt(element.srcElement.id.split('_')[6])
    equipment_id = document.getElementById(event.srcElement.id).value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `index=${index};equipment_id=${equipment_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Populate material rate fields when a material is selected.
  updateQuoteTaskMaterial (element) {
    var index, material_id
    index = index = parseInt(element.srcElement.id.split('_')[6])
    material_id = document.getElementById(element.srcElement.id).value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `index=${index};material_id=${material_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/quotes/${this.quoteIdTarget.value}/quote_tasks/${this.quoteTaskIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    $('#quote-task-nested-field-labour .selectpicker').selectpicker()
    $('#quote-task-nested-field-equipment .selectpicker').selectpicker()
    $('#quote-task-nested-field-material .selectpicker').selectpicker()
  }
}
