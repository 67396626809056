import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    //initialize selectpicker on new rate nested fields
    const materialRates = document.querySelector("#material-material-rates");
    if (materialRates != null) {
      materialRates.addEventListener('cocoon:after-insert', (added_item) => {
        return window.$('.selectpicker').selectpicker();
      });
    };
  }
}
