import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['quoteId', 'quoteTaskId']

  // When a new set of nested fields is produced initialize
  // the selectpicker for live search enabled select boxes
  connect () {
    this.initialize_selects
    return window
      .$('.selectpicker.with-ajax')
      .selectpicker()
      .ajaxSelectPicker({
        ajax: {
          url: this.ajaxURL,
          type: 'GET',
          dataType: 'json',
          data: {
            option: 'material',
            q: '{{{q}}}'
          }
        },
        locale: {
          emptyTitle: 'Select and Begin Typing'
        },
        requestDelay: 500,
        minLength: 2,
        log: 0,
        preprocessData: function (data) {
          var array, i, l
          i = 0
          l = data.length
          array = []
          if (l) {
            i = 0
            while (i < l) {
              array.push(
                $.extend(true, data[i], {
                  text: data[i].name,
                  value: data[i].id,
                  data: {
                    subtext: data[i].part_number
                  }
                })
              )
              i++
            }
          }
          return array
        }
      })
  }

  // Populate material rate fields when a material is selected.
  updateQuoteTaskMaterial (element) {
    var material_id
    material_id = document.getElementById(element.srcElement.id).value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `material_id=${material_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/quotes/${this.quoteIdTarget.value}/quote_tasks/${this.quoteTaskIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    $('#quote_task_material_material_id').selectpicker()
  }
}
