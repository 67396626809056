import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['reportId']

  connect () {
    const analogTests = document.querySelector(
      '#calibration-report-analog-tests'
    )
    if (analogTests != null) {
      this.initialize_selects
      analogTests.addEventListener('cocoon:after-insert', added_item => {
        return window.$('.selectpicker').selectpicker()
      })
    }
  }

  // Request the tab data submiting the acive tab with AJAX
  updateDevicesTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/calibration_reports/${this.reportIdTarget.value}/calibration_report_analogs`
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#calibration-report-analog-tests .selectpicker').selectpicker()
  }
}
