import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  // Ensure the input and io_mappable_type selects are disabled on load.
  connect () {
    this.initialize_selects
    if (document.getElementById('channel_map_input').disabled === false) {
      window.$('#channel_map_input').prop('disabled', true)
    }
    if (
      document.getElementById('channel_map_io_mappable_type').disabled === false
    ) {
      window.$('#channel_map_io_mappable_type').prop('disabled', true)
    }
  }

  // Update the availble devices (select box) when the io_mappable_type
  // changes via AJAX.
  updateDevices () {
    var input, io_mappable_type
    input = $('#channel_map_input option:selected').val()
    io_mappable_type = $('#channel_map_io_mappable_type option:selected').val()
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `input=${input};io_mappable_type=${io_mappable_type}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // On click enabled the channel map input select box.
  enableChannelMode () {
    if (document.getElementById('channel_map_input').disabled === true) {
      window.$('#channel_map_input').prop('disabled', false)
      return window.$('#channel_map_input').selectpicker('refresh')
    }
  }

  // On click enabled the channel map io_mappable_type select box.
  enableChannelType () {
    if (
      document.getElementById('channel_map_io_mappable_type').disabled === true
    ) {
      window.$('#channel_map_io_mappable_type').prop('disabled', false)
      return window.$('#channel_map_io_mappable_type').selectpicker('refresh')
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return 'selects_ajax'
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#channel_map_io_mappable_type').selectpicker()
    window.$('#channel_map_input').selectpicker()
    window.$('#channel_map_channel_type').selectpicker()
    window.$('#channel_map_io_mappable_id').selectpicker()
  }
}
