import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['vendorId']

  connect () {
    return this.initialize_selects
  }

  // When vendor_country_code select changes reload provinces/states with AJAX
  //
  // #vendor_country_code
  updateVendorProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/vendors/${this.vendorIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#vendor_branch_id').selectpicker()
    window.$('#vendor_country_code').selectpicker()
    window.$('#vendor_province_code').selectpicker()
    window.$('#vendor_shipper_id').selectpicker()
  }
}
