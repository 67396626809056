import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="purchase-order-items"
export default class extends Controller {
  static targets = ['purchaseOrderId', 'purchaseOrderItemId']

  connect () {
    this.initialize_selects
    return window
      .$('.selectpicker.with-ajax')
      .selectpicker()
      .ajaxSelectPicker({
        ajax: {
          url: this.ajaxURL,
          type: 'GET',
          dataType: 'json',
          data: {
            q: '{{{q}}}'
          }
        },
        locale: {
          emptyTitle: 'Select and Begin Typing'
        },
        requestDelay: 500,
        minLength: 2,
        log: 0,
        preprocessData: function (data) {
          var array, i, l
          i = 0
          l = data.length
          array = []
          if (l) {
            i = 0
            while (i < l) {
              array.push(
                $.extend(true, data[i], {
                  text: data[i].name,
                  value: data[i].id,
                  data: {
                    subtext: data[i].part_number
                  }
                })
              )
              i++
            }
          }
          return array
        }
      })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/purchase_orders/${this.purchaseOrderIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#purchase_order_item_material_id').selectpicker()
  }
}
