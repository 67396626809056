// Show tooltips if found
window.$('[data-toggle="tooltip"]').tooltip()

// On page load initialize selectpicker
// $(document).on('turbo:load', function () {
//   if (document.querySelector('.selectpicker')) {
//     return window.$('.selectpicker').selectpicker()
//   }
// })

// Maintain the sidebar pushmenu state in localStorage and retrieve it so
// that the sidebar maintains its open/closed state through page changes.
//
// Set local storage if shown
window.$(document).on('shown.lte.pushmenu', function (e) {
  window.$('.hide-collapse-form').show()
  window.localStorage.setItem('sidebar', 'expanded')
})

// Set local storage if collapsed
window.$(document).on('collapsed.lte.pushmenu', function (e) {
  window.$('.hide-collapse-form').hide()
  window.localStorage.setItem('sidebar', 'collapsed')
})

// Retrieve local storage value and change class of body #
$(document).on('turbo:load', function () {
  if ($(window).width() <= 992) {
    window.$('body').addClass('sidebar-collapse')
    return $('body').removeClass('sidebar-open')
  } else {
    if (window.localStorage.getItem('sidebar') !== null) {
      if (window.localStorage.getItem('sidebar') === 'collapsed') {
        window.$('.hide-collapse-form').hide()
        return $('body').addClass('sidebar-collapse')
      }
    }
  }
})
