// See the Pagy documentation: https://ddnexus.github.io/pagy/extras#javascript

!(function () {
  function n (n) {
    if (Array.isArray(n)) return n
  }
  function e (n) {
    if (
      Symbol.iterator in Object(n) ||
      '[object Arguments]' === Object.prototype.toString.call(n)
    )
      return Array.from(n)
  }
  function t () {
    throw new TypeError('Invalid attempt to destructure non-iterable instance')
  }
  function r (r, a) {
    return n(r) || e(r) || t()
  }
  var a,
    o,
    i,
    c,
    u,
    f,
    l =
      ((a = new ResizeObserver(function (n) {
        return n.forEach(function (n) {
          return n.target.querySelectorAll('.pagy-rjs').forEach(function (n) {
            return n.pagyRender()
          })
        })
      })),
      (o = function (n, e) {
        var t,
          o = r(e),
          i = o[0],
          c = o[1],
          u = o[2],
          l = o[3],
          p = null !== (t = n.parentElement) && void 0 !== t ? t : n,
          s = Object.keys(c)
            .map(function (n) {
              return parseInt(n)
            })
            .sort(function (n, e) {
              return e - n
            }),
          y = -1,
          g = function (n, e, t) {
            return n.replace(/__pagy_page__/g, e).replace(/__pagy_label__/g, t)
          }
        ;(n.pagyRender = function () {
          var e =
            s.find(function (n) {
              return n < p.clientWidth
            }) || 0
          if (e !== y) {
            var t,
              r = i.before,
              a = c[e.toString()],
              o =
                null !== (t = null == u ? void 0 : u[e.toString()]) &&
                void 0 !== t
                  ? t
                  : a.map(function (n) {
                      return n.toString()
                    })
            for (var v in a) {
              var d = a[v],
                _ = o[v]
              r +=
                'string' == typeof l && 1 === d
                  ? f(g(i.link, d.toString(), _), l)
                  : 'number' == typeof d
                  ? g(i.link, d.toString(), _)
                  : 'gap' === d
                  ? i.gap
                  : g(i.active, d, _)
            }
            ;(r += i.after),
              (n.innerHTML = ''),
              n.insertAdjacentHTML('afterbegin', r),
              (y = e)
          }
        })(),
          n.classList.contains('pagy-rjs') && a.observe(p)
      }),
      (i = function (n, e) {
        var t = r(e),
          a = t[0],
          o = t[1]
        return u(
          n,
          function (n) {
            return [n, a.replace(/__pagy_page__/, n)]
          },
          o
        )
      }),
      (c = function (n, e) {
        var t = r(e),
          a = t[0],
          o = t[1],
          i = t[2]
        u(
          n,
          function (n) {
            var e = Math.max(Math.ceil(a / parseInt(n)), 1).toString()
            return [
              e,
              o.replace(/__pagy_page__/, e).replace(/__pagy_items__/, n)
            ]
          },
          i
        )
      }),
      (u = function (n, e, t) {
        var a = n.querySelector('input'),
          o = a.value,
          i = function () {
            if (a.value !== o) {
              var i = r(
                  [a.min, a.value, a.max].map(function (n) {
                    return parseInt(n) || 0
                  })
                ),
                c = i[0],
                u = i[1],
                l = i[2]
              if (u < c || u > l) return (a.value = o), void a.select()
              var p = r(e(a.value)),
                s = p[0],
                y = p[1]
              'string' == typeof t && '1' === s && (y = f(y, t)),
                n.insertAdjacentHTML('afterbegin', y),
                n.querySelector('a').click()
            }
          }
        ;['change', 'focus'].forEach(function (n) {
          return a.addEventListener(n, a.select)
        }),
          a.addEventListener('focusout', i),
          a.addEventListener('keypress', function (n) {
            'Enter' === n.key && i()
          })
      }),
      (f = function (n, e) {
        return n.replace(
          new RegExp(
            '(\\?|&amp;)'.concat(e, '=1\\b(?!&amp;)|\\b').concat(e, '=1&amp;')
          ),
          ''
        )
      }),
      {
        version: '5.9.2',
        init: function (r) {
          var a,
            u = (r instanceof Element ? r : document).querySelectorAll(
              '[data-pagy]'
            ),
            f = !0,
            l = !1,
            p = void 0
          try {
            for (
              var s, y = u[Symbol.iterator]();
              !(f = (s = y.next()).done);
              f = !0
            ) {
              var g = s.value
              try {
                var v = Uint8Array.from(
                    atob(g.getAttribute('data-pagy')),
                    function (n) {
                      return n.charCodeAt(0)
                    }
                  ),
                  d =
                    n((a = JSON.parse(new TextDecoder().decode(v)))) ||
                    e(a) ||
                    t(),
                  _ = d[0],
                  m = d.slice(1)
                'nav' === _
                  ? o(g, m)
                  : 'combo' === _
                  ? i(g, m)
                  : 'selector' === _
                  ? c(g, m)
                  : console.warn(
                      "Skipped Pagy.init() for: %o\nUnknown keyword '%s'",
                      g,
                      _
                    )
              } catch (n) {
                console.warn('Skipped Pagy.init() for: %o\n%s', g, n)
              }
            }
          } catch (n) {
            ;(l = !0), (p = n)
          } finally {
            try {
              f || null == y.return || y.return()
            } finally {
              if (l) throw p
            }
          }
        }
      })
  window.Pagy = l
})()

window.addEventListener('turbo:load', Pagy.init)
window.Pagy = Pagy
