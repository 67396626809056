import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    this.initialize_selects

    // Disable the company and name fields if selected from drop-down.
    this.signOnTypeHideShow
    // Enable signature pad entry
    if (this.element.id == 'hazard-sign-on') {
      if (document.querySelector('.sigPad.unsigned') !== null) {
        return window.$('.sigPad').signaturePad({
          drawOnly: true,
          lineTop: 60,
          output: '#hazard_sign_on_signature'
        })
      }
    }
  }

  // Update the disabled attribute of the 'name' and 'company' fields when the
  // hazard_signonable_id changes.  If the select option is none (Un-Listed) a
  // name and company is enabled for user input.
  updateSignOn () {
    this.signOnTypeHideShow
  }

  // Disable the company and name fields if selected from drop-down.
  get signOnTypeHideShow () {
    if (
      document.getElementById('hazard_sign_on_hazard_signonable_id') != null
    ) {
      if (
        document.getElementById('hazard_sign_on_hazard_signonable_id').value ===
        ''
      ) {
        document.getElementById('hazard_sign_on_name').disabled = false
        if (document.getElementById('hazard_sign_on_company') != null) {
          return (document.getElementById(
            'hazard_sign_on_company'
          ).disabled = false)
        }
      } else {
        document.getElementById('hazard_sign_on_name').disabled = true
        if (document.getElementById('hazard_sign_on_company') != null) {
          return (document.getElementById(
            'hazard_sign_on_company'
          ).disabled = true)
        }
      }
    } else {
      document.getElementById('hazard_sign_on_name').disabled = false
      if (document.getElementById('hazard_sign_on_company') != null) {
        return (document.getElementById(
          'hazard_sign_on_company'
        ).disabled = false)
      } else {
        document.getElementById('hazard_sign_on_name').disabled = true
        if (document.getElementById('hazard_sign_on_company') != null) {
          return (document.getElementById(
            'hazard_sign_on_company'
          ).disabled = true)
        }
      }
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    if (document.getElementById('hazard-sign-on') != null) {
      window.$('#hazard_sign_on_hazard_signonable_id').selectpicker()
    }
  }
}
