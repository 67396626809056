import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['ownableType', 'calibrationReportId']

  connect () {
    if (this.element.id != 'calibration-report-show') {
      return this.initialize_selects
    }
  }

  // Update the availble Area, and Locations (select box) when the
  // owner changes via AJAX.
  //
  // #calibration_report_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations (select box) when the area changes via AJAX.
  //
  // #calibration_report_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the tab data submiting the acive tab with AJAX
  updateDevicesTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    if (this.element.id != 'calibration-report-show') {
      return `/calibration_reports/${this.calibrationReportIdTarget.value}/selects_ajax`
    } else {
      return `/calibration_reports/${this.calibrationReportIdTarget.value}`
    }
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#calibration_report_ownable_id').selectpicker()
    window.$('#calibration_report_area_id').selectpicker()
    window.$('#calibration_report_location_id').selectpicker()
  }
}
