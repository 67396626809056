import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.initialize_selects
    return this.tradeManagerHideShow
  }

  // On page load show/hide the appropriate attributes.
  updateManager () {
    this.tradeManagerHideShow
  }

  get tradeManagerHideShow () {
    if (document.getElementById('trade_management').checked === true) {
      return (document
        .getElementById('trade_manager_id')
        .closest('div.row').style.display = 'None')
    }
    if (document.getElementById('trade_management').checked === false) {
      return (document
        .getElementById('trade_manager_id')
        .closest('div.row').style.display = '')
    }
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#trade_manager_id').selectpicker()
  }
}
