import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['quoteId', 'quoteTaskId']

  // When a new set of nested fields is produced initialize
  // the selectpicker for live search enabled select boxes
  connect () {
    this.initialize_selects
  }

  // Populate equipment rate fields when a equipment is selected.
  updateQuoteTaskEquipment (event) {
    var equipment_id
    equipment_id = document.getElementById(event.srcElement.id).value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `equipment_id=${equipment_id}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/quotes/${this.quoteIdTarget.value}/quote_tasks/${this.quoteTaskIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    $('#quote_task_equipment_equipment_rate_id').selectpicker()
  }
}
