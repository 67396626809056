import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ransack"
export default class extends Controller {
  remove_fields (event) {
    event.srcElement.closest('.fields').remove()
  }

  add_fields (event) {
    var new_id, regexp, element, content, type
    element = document.createElement('div')
    content = event.srcElement.getAttribute('data-content')
    type = event.srcElement.getAttribute('data-field-type')
    new_id = new Date().getTime()
    regexp = new RegExp('new_' + type, 'g')
    element.innerHTML = content.replace(regexp, new_id)
    return event.srcElement.before(element)
  }

  nest_fields (event) {
    var id_regexp, new_id, object_name, sanitized_object_name, template
    new_id = new Date().getTime()
    id_regexp = new RegExp('new_' + type, 'g')
    template = {}
    object_name = event.srcElement
      .closest('.fields')
      .getAttribute('data-object-name')
    sanitized_object_name = object_name
      .replace(/\]\[|[^-a-zA-Z0-9:.]/g, '_')
      .replace(/_$/, '')
    template = template.replace(/new_object_name\[/g, object_name + '[')
    template = template.replace(/new_object_name_/, sanitized_object_name + '_')
    return event.srcElement.before(template.replace(id_regexp, new_id))
  }
}
