import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'workRequestId',
    'ownableType',
    'requestableType',
    'locationId'
  ]

  connect () {
    if (this.element.id != 'work-requests-index') {
      this.initialize_selects
      this.requestTypeHideShow
      this.requestDeviceHideShow
      return this.assignedtoHideShow
    }
  }

  // Update the availble Area, Locations, (select box) when the owner changes via AJAX.
  updateOwnable (event) {
    var ownable_type, ownable_id, device_type
    ownable_type = this.ownableTypeTarget.value.toLowerCase()
    ownable_id = event.srcElement.value
    device_type = this.requestableTypeTarget.value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=owner_${ownable_type};ownable_id=${ownable_id};device_type=${device_type}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations (select box) when the area changes via AJAX.
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value};device_type=${this.requestableTypeTarget.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble devices (select box) when the location
  // changes via AJAX.
  updateLocation (event) {
    var device_type
    device_type = this.requestableTypeTarget.value
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value};device_type=${device_type}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble devices (select box) when the device type
  // changes via AJAX.
  updateRequestableType (event) {
    this.requestDeviceHideShow
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=requestable;location_id=${this.locationIdTarget.value};device_type=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble employees (select box) when the branch
  // changes via AJAX.
  updateBranch (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;branch=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the form data submiting the acive tab with AJAX
  updateTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Update the form when the request type has changed.
  updateRequestType () {
    return this.requestTypeHideShow
  }

  // Update the form when approval has state has changed to approved.
  updateRequestApprove () {
    return this.assignedtoHideShow
  }
  // Update the form when approval has state has changed to denied.
  updateRequestDeny () {
    return this.assignedtoHideShow
  }
  // Update the form when approval has state has changed to under review.
  updateRequestReview () {
    return this.assignedtoHideShow
  }

  // Hide the appropriate attributes based on the request type.
  get requestTypeHideShow () {
    if (document.getElementById('work_request_reoccuring').checked === true) {
      return (document
        .getElementById('work_request_reoccur_interval')
        .closest('div.row').style.display = '')
    } else {
      return (document
        .getElementById('work_request_reoccur_interval')
        .closest('div.row').style.display = 'None')
    }
  }

  // Hide the devices based on the device type.
  get requestDeviceHideShow () {
    if (document.getElementById('work_request_requestable_type').value === '') {
      return (document
        .getElementById('work_request_requestable_id')
        .closest('div.row').style.display = 'None')
    } else {
      return (document
        .getElementById('work_request_requestable_id')
        .closest('div.row').style.display = '')
    }
  }

  get assignedtoHideShow () {
    if (document.getElementById('work_request_approved_approve') != null) {
      if (
        document.getElementById('work_request_approved_approve').checked ===
        true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = ''
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = '')
      }
    }
    if (document.getElementById('work_request_approved_deny') != null) {
      if (
        document.getElementById('work_request_approved_deny').checked === true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = 'None'
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = 'None')
      }
    }
    if (document.getElementById('work_request_approved_review') != null) {
      if (
        document.getElementById('work_request_approved_review').checked === true
      ) {
        document
          .getElementById('work_request_assigned_to_id')
          .closest('div.row').style.display = 'None'
        return (document
          .getElementById('work_request_branch_id')
          .closest('div.row').style.display = 'None')
      }
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/work_requests/${this.workRequestIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#work_request_ownable_id').selectpicker()
    window.$('#work_request_area_id').selectpicker()
    window.$('#work_request_location_id').selectpicker()
    window.$('#work_request_requestable_type').selectpicker()
    window.$('#work_request_requestable_id').selectpicker()
    window.$('#work_request_work_type').selectpicker()
    window.$('#work_request_priority').selectpicker()
    window.$('#work_request_completed_by_id').selectpicker()
    window.$('#work_request_assigned_to_id').selectpicker()
    window.$('#work_request_branch_id').selectpicker()
  }
}
