import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['ownableType', 'multivariableDeviceId']

  connect () {
    if (this.element.id != 'multivariable-device-show') {
      return this.initialize_selects
    }
  }

  // Update the availble Area, Locations, Automation devices (select box) when the
  // owner changes via AJAX.
  //
  // #multivariable_device_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations, Automation devices (select box) when the area
  // changes via AJAX.
  //
  // #multivariable_device_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Automation devices (select box) when the location
  // changes via AJAX.
  //
  // #multivariable_device_location_id
  updateLocation (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=location;location_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // When associated with a automation device set the Manufacturer, Model, and
  // Serial from the AutomationDevice via AJAX.
  //
  // #multivariable_device_automation_device_id
  updateAutomationDevice (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=multivariable_device;automation_device_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Request the show tab data submiting the acive tab with AJAX
  updateShowTab (event) {
    var tab
    window.$('.spinner-border').removeClass('d-none')
    tab = event.target.id.split('-')[2]
    Rails.ajax({
      url: '',
      type: 'GET',
      dataType: 'script',
      data: `active_tab=${tab}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      },
      success: function (data, textStatus, jqXHR) {
        return window.$('.spinner-border').addClass('d-none')
      }
    })
    return window.$(event.target).tab('show')
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/multivariable_devices/${this.multivariableDeviceIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#multivariable_device_ownable_id').selectpicker()
    window.$('#multivariable_device_area_id').selectpicker()
    window.$('#multivariable_device_location_id').selectpicker()
    window.$('#multivariable_device_automation_device_id').selectpicker()
    window.$('#multivariable_device_comms_protocol').selectpicker()
  }
}
