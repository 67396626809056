import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['serviceAgreementId']

  connect () {
    this.initialize_selects

    const agreementLabourRates = document.querySelector(
      '#service-agreement-labour-rates'
    )
    if (agreementLabourRates != null) {
      agreementLabourRates.addEventListener(
        'cocoon:after-insert',
        added_item => {
          return window.$('.selectpicker').selectpicker()
        }
      )
    }
    const agreementEquipmentRates = document.querySelector(
      '#service-agreement-equipment-rates'
    )
    if (agreementEquipmentRates != null) {
      agreementEquipmentRates.addEventListener(
        'cocoon:after-insert',
        added_item => {
          return window.$('.selectpicker').selectpicker()
        }
      )
    }
  }

  // Update labour rates
  updateLabourRates (event) {
    var index, trade_id
    trade_id = document.getElementById(event.srcElement.id).value
    index = parseInt(event.srcElement.id.split('_')[5])
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=labour;trade_id=${trade_id};index=${index}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update equipment rates
  updateEquipmentRates (event) {
    var index, equipment_rate_id
    equipment_rate_id = document.getElementById(event.srcElement.id).value
    index = parseInt(event.srcElement.id.split('_')[6])
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=equipment;equipment_rate_id=${equipment_rate_id};index=${index}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/service_agreements/${this.serviceAgreementIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#service_agreement_branch_id').selectpicker()
    window.$('#service_agreement_customer_id').selectpicker()
    window.$('#service-agreement-labour-rates .selectpicker').selectpicker()
    window.$('#service-agreement-equipment-rates .selectpicker').selectpicker()
  }
}
