import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['ownableType', 'controlPanelId']

  connect () {
    return this.initialize_selects
  }

  // Update the availble Area, Locations (select box) when the owner changes via AJAX.
  //
  // #control_panel_ownable_id
  updateOwnable (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=${this.ownableTypeTarget.value.toLowerCase()};ownable_id=${
        event.srcElement.value
      }`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Update the availble Locations (select box) when the area changes via AJAX.
  //
  // #control_panel_area_id
  updateArea (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `option=area;area_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/control_panels/${this.controlPanelIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#control_panel_ownable_id').selectpicker()
    window.$('#control_panel_area_id').selectpicker()
    window.$('#control_panel_location_id').selectpicker()
  }
}
