import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['purchaseOrderId']
  // When a new set of nested fields is produced initialize
  // the selectpicker for live search enabled select boxes
  connect () {
    this.initialize_selects
    const itemFields = document.querySelector(
      '#purchase-order-nested-field-items'
    )

    itemFields.addEventListener('cocoon:after-insert', added_item => {
      return window
        .$('#purchase-order-nested-field-items')
        .find('.with-ajax')
        .selectpicker()
        .ajaxSelectPicker({
          ajax: {
            url: this.ajaxURL,
            type: 'GET',
            dataType: 'json',
            data: {
              q: '{{{q}}}'
            }
          },
          locale: {
            emptyTitle: 'Select and Begin Typing'
          },
          requestDelay: 500,
          minLength: 2,
          log: 0,
          preprocessData: function (data) {
            var array, i, l
            i = 0
            l = data.length
            array = []
            if (l) {
              i = 0
              while (i < l) {
                array.push(
                  $.extend(true, data[i], {
                    text: data[i].name,
                    value: data[i].id,
                    data: {
                      subtext: data[i].part_number
                    }
                  })
                )
                i++
              }
            }
            return array
          }
        })
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/purchase_orders/${this.purchaseOrderIdTarget.value}/selects_ajax`
  }

  get initialize_selects () {
    window.$('#purchase_order_branch_id').selectpicker()
    window.$('#purchase_order_employee_id').selectpicker()
    window.$('#purchase_order_location_id').selectpicker()
    window.$('#purchase_order_vendor_id').selectpicker()
    window.$('#purchase-order-nested-field-items .selectpicker').selectpicker()
  }
}
