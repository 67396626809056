import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    var items, new_item
    this.initialize_selects

    // Initialize hazard steps selectpicker
    const hazardStepFields = document.querySelector(
      '#hazard-assessment-hazard-steps'
    )
    if (hazardStepFields != null) {
      hazardStepFields.addEventListener('cocoon:after-insert', added_item => {
        items = document.querySelectorAll('[name*="position]"]')
        new_item = items[items.length - 1]
        new_item.value = this.highestPosition
        console.log(this.highestPosition)
        return window.$('.selectpicker').selectpicker()
      })
    }

    if (this.element.id == 'hazard-assessment-approval') {
      if (document.querySelector('.sigPad.unsigned') !== null) {
        return window.$('.sigPad').signaturePad({
          drawOnly: true,
          lineTop: 60,
          output: '#hazard_assessment_signature'
        })
      }
    }
  }

  get highestPosition () {
    var hold, max, i, positions
    positions = document.querySelectorAll('[name*="position]"]')
    max = 0
    if (positions.length > 1) {
      for (i = 0; i < positions.length; ++i) {
        hold = parseInt(positions[i].value)
        if (!isNaN(hold)) {
          if (hold >= max) {
            max = hold
          }
        }
      }
    }
    return max + 1
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    if (document.getElementById('hazard-assessment-approval') != null) {
      window.$('#hazard_assessment_customer_rep_signed_id').selectpicker()
    } else {
      window.$('#hazard_assessment_hazard_msds').selectpicker()
      window.$('#hazard_assessment_complete_clean').selectpicker()
      window.$('#hazard_assessment_complete_warnings_removed').selectpicker()
      window.$('#hazard_assessment_complete_hazards_remain').selectpicker()
      window.$('#hazard_assessment_complete_injury_occur').selectpicker()
      window.$('#hazard-assessment-hazard-steps .selectpicker').selectpicker()
    }
  }
}
