import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['workTicketId', 'equipmentEntryId']

  connect () {
    return this.initialize_selects
  }

  // Update rate information when equipment changes
  //
  // #equipment_entry_equipment_rate_id
  updateEquipment (event) {
    if (this.element.id == 'equipment-entry-edit') {
      return Rails.ajax({
        url: this.ajaxURL,
        type: 'GET',
        dataType: 'script',
        data: `equipment_rate_id=${event.srcElement.value}`,
        error: function (jqXHR, textStatus, errorThrown) {
          return console.log('AJAX Error: ' + textStatus)
        }
      })
    }
  }

  // Copy the Equipment Rate data from the rate table to the form fields
  copyEquipRate () {
    var table
    table = document.getElementById('equipment-rate-table')
    document.getElementById(
      'equipment_entry_rate'
    ).value = table.rows[9].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    if (document.getElementById('equipment_entry_pay') !== null) {
      return (document.getElementById(
        'equipment_entry_pay'
      ).value = table.rows[10].cells[1].innerHTML.replace(/[^\d\.]/g, ''))
    }
  }

  // Copy the Equipment Rate data from the service agreement table to the form fields
  copySAEquipRate () {
    var table
    table = document.getElementById('service-agreement-equipment-rate-table')
    document.getElementById(
      'equipment_entry_rate'
    ).value = table.rows[5].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    if (document.getElementById('equipment_entry_pay') !== null) {
      return (document.getElementById(
        'equipment_entry_pay'
      ).value = table.rows[10].cells[1].innerHTML.replace(/[^\d\.]/g, ''))
    }
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/work_tickets/${this.workTicketIdTarget.value}/equipment_entries/${this.equipmentEntryIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selets
  get initialize_selects () {
    window.$('#equipment_entry_equipment_rate_id').selectpicker()
  }
}
