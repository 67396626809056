import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['shipperId']

  connect () {
    return this.initialize_selects
  }

  // When shipper_country_code select changes reload provinces/states with AJAX
  //
  // #shipper_country_code
  updateShipperProvinces (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `country_code=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/shippers/${this.shipperIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#shipper_branch_id').selectpicker()
    window.$('#shipper_country_code').selectpicker()
    window.$('#shipper_province_code').selectpicker()
  }
}
