import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="labour-rates"
export default class extends Controller {
  connect () {
    return this.initialize_selects
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#labour_rate_branch_id').selectpicker()
    window.$('#labour_rate_trade_id').selectpicker()
  }
}
