import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['workTicketId', 'labourEntryId']

  connect () {
    return this.initialize_selects
  }

  // Update labour rate cards on selected labour rate
  //
  // #labour_entry_labour_rate_id
  updateLabour (event) {
    if (this.element.id == 'labour-entry-edit') {
      return Rails.ajax({
        url: this.ajaxURL,
        type: 'GET',
        dataType: 'script',
        data: `labour_rate_id=${event.srcElement.value}`,
        error: function (jqXHR, textStatus, errorThrown) {
          return console.log('AJAX Error: ' + textStatus)
        }
      })
    }
  }

  // Update trade based on selected employee
  //
  // #labour_entry_employee_id
  updateEmployee (event) {
    return Rails.ajax({
      url: this.ajaxURL,
      type: 'GET',
      dataType: 'script',
      data: `employee_id=${event.srcElement.value}`,
      error: function (jqXHR, textStatus, errorThrown) {
        return console.log('AJAX Error: ' + textStatus)
      }
    })
  }

  // Copy the Labour Rate data from the rate table to the form fields
  copyLabourRate () {
    var table
    table = document.getElementById('labour-rate-table')
    document.getElementById(
      'labour_entry_regular_rate'
    ).value = table.rows[3].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_overtime_rate'
    ).value = table.rows[4].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_holiday_rate'
    ).value = table.rows[5].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_regular_pay'
    ).value = table.rows[6].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_overtime_pay'
    ).value = table.rows[7].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    return (document.getElementById(
      'labour_entry_holiday_pay'
    ).value = table.rows[8].cells[1].innerHTML.replace(/[^\d\.]/g, ''))
  }

  // Copy the labour Rate data from the service agreement table to the form fields
  copySALabourRate () {
    var table
    table = document.getElementById('service-agreement-labour-rate-table')
    document.getElementById(
      'labour_entry_regular_rate'
    ).value = table.rows[5].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_overtime_rate'
    ).value = table.rows[6].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_holiday_rate'
    ).value = table.rows[7].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_regular_pay'
    ).value = table.rows[8].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    document.getElementById(
      'labour_entry_overtime_pay'
    ).value = table.rows[9].cells[1].innerHTML.replace(/[^\d\.]/g, '')
    return (document.getElementById(
      'labour_entry_holiday_pay'
    ).value = table.rows[10].cells[1].innerHTML.replace(/[^\d\.]/g, ''))
  }

  // Determine the ajax URL
  get ajaxURL () {
    return `/work_tickets/${this.workTicketIdTarget.value}/labour_entries/${this.labourEntryIdTarget.value}/selects_ajax`
  }

  // Initialize selectpicker selects
  get initialize_selects () {
    window.$('#labour_entry_employee_id').selectpicker()
    window.$('#labour_entry_labour_rate_id').selectpicker()
  }
}
